import { toPairs } from 'lodash-es'

export const roleArr = [
  'Forskola.SuperAdmin',
  'Forskola.MunicipalityAdmin',
  'Forskola.MunicipalityUser',
  'Forskola.SchoolAdmin',
  'Forskola.SchoolUser',
]

export const roles = {
  SuperAdmin: 'Forskola.SuperAdmin',
  MunicipalityAdmin: 'Forskola.MunicipalityAdmin',
  MunicipalityUser: 'Forskola.MunicipalityUser',
  SchoolAdmin: 'Forskola.SchoolAdmin',
  SchoolUser: 'Forskola.SchoolUser',
}

export const roleLabels = {
  [roles.SuperAdmin]: 'Superadmin',
  [roles.MunicipalityAdmin]: 'Kommunadmin',
  [roles.MunicipalityUser]: 'Kommunanvändare',
  [roles.SchoolAdmin]: 'Skoladmin',
  [roles.SchoolUser]: 'Skolanvändare',
}
export const roleLabelsEn = {
  [roles.SuperAdmin]: 'Super Admin',
  [roles.MunicipalityAdmin]: 'Municipal Admin',
  [roles.MunicipalityUser]: 'Municipal Users',
  [roles.SchoolAdmin]: 'School Admin',
  [roles.SchoolUser]: 'School Users',
}

export const roleOptions = toPairs(roleLabels).map(([value, label]) => ({
  value,
  label,
}))
export const roleOptionsEn = toPairs(roleLabelsEn).map(([value, label]) => ({
  value,
  label,
}))