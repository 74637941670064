import React from 'react'
import FullPageSpinner from './FullPageSpinner'
import { Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export default function AuthLoading() {
  const { t, i18n } = useTranslation()
  return (
    <FullPageSpinner>
      <Text color="white" fontSize="2xl">
      {t('Authenticating_Spinner')}
      </Text>
    </FullPageSpinner>
  )
}
