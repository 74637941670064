import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  colors: {
    brandPrimary: {
      50: '#e1f1ff',
      100: '#b4d1ff',
      200: '#85b2f9',
      300: '#5694f5',
      400: '#2975f2',
      500: '#125bd8',
      600: '#0a47a9',
      700: '#03337a',
      800: '#001e4b',
      900: '#000a1e',
    },
    mainBlue: {
      50: '#dee4f2',
      500: '#277aff',
      700: '#41567e',
      900: '#2a244d',
    },
    error: '#db2269',
    darkIndigo: '#2a0f74',
  },
  styles: {
    global: {
      body: {
        fontFamily: 'RedHatDisplay',
        lineHeight: '1.15',
      },
    },
  },
  shadows: {
    outline: 'none',
  },
  components: {
    Input: {
      defaultProps: {
        focusBorderColor: 'brandPrimary.300',
      },
      sizes: {
        md: {
          field: {
            height: '32px',
          },
        },
        lg: {
          field: {
            height: '40px',
            fontSize: '16px',
          },
        },
      },
      variants: {
        outline: {
          field: {
            _readOnly: {
              bg: '#f8f8f8',
            },
            _placeholder: {
              color: 'mainBlue.700',
              fontSize: '12px',
            },
          },
        },
      },
    },

    Button: {
      baseStyle: {
        color: 'white',
        borderRadius: '40px',
        fontSize: 'sm',
      },
      variants: {
        solid: {
          bg: 'brandPrimary.300',
          _hover: {
            bg: 'brandPrimary.400',
            _disabled: {
              bg: 'brandPrimary.200',
            },
          },
          _active: {
            bg: 'brandPrimary.500',
          },
        },
        outline: {
          borderColor: 'brandPrimary.300',
          color: 'brandPrimary.300',
          _hover: {
            bg: 'brandPrimary.50',
            borderColor: 'brandPrimary.400',
            _disabled: {
              borderColor: 'brandPrimary.200',
            },
          },
          _active: {
            bg: 'brandPrimary.100',
            borderColor: 'brandPrimary.500',
          },
        },
        ghost: {
          color: 'mainBlue.500',
        },
      },
    },

    Tabs: {
      baseStyle: {
        tabpanel: {
          p: 0,
        },
      },
      variants: {
        line: {
          tab: {
            _selected: {
              fontWeight: 'bold',
              color: '#2a244d',
              borderColor: 'mainBlue.500',
              borderBottomWidth: '4px',
            },
          },
        },
      },
    },

    Drawer: {
      baseStyle: {},
      variants: {
        'notification-panel': {
          dialog: {
            maxH: 'calc(100vh - 60px)',
            mt: '61px',
            border: '1px solid #bfd0eb',
            borderTopLeftRadius: 'md',
            borderBottomLeftRadius: 'md',
          },
          header: {
            p: '25px',
          },
          closeButton: {
            top: '20px',
            right: '20px',
          },
          body: {
            p: 0,
          },
        },
      },
    },
  },
})

export default theme
