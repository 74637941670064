import { BaseUser } from '../data-models/user'
import { roleArr, roleLabels, roles } from './roleMappings'

const RoleService = {
  isSuperAdmin: (user: BaseUser) => user.role === roles.SuperAdmin,
  XCanDelete: (user: BaseUser) => user.role === roles.MunicipalityUser,

  isMunicipalityAdmin: (user: BaseUser) =>
    [roles.SuperAdmin, roles.MunicipalityAdmin].includes(user.role),
  isSchoolAdmin: (user: BaseUser) =>
    [roles.SuperAdmin, roles.SchoolAdmin].includes(user.role),
  isAdmin: (user: BaseUser) =>
    [roles.SuperAdmin, roles.MunicipalityAdmin, roles.SchoolAdmin].includes(
      user.role
    ),
  hasMunicipalitySchoolsSubset: (user: BaseUser) =>
    [roles.SchoolAdmin, roles.SchoolUser].includes(user.role),

  getEditableRoles: (forUser: BaseUser) =>
    roleArr.slice(roleArr.indexOf(forUser.role)),

  XCanEditY: (editor: BaseUser, editee: BaseUser) =>
    RoleService.isSuperAdmin ||
    (RoleService.isAdmin(editor) &&
      RoleService.getEditableRoles(editor).includes(editee.role)),

  hydrateRoles: (roles: string[]) =>
    roles.map(r => ({ value: r, label: roleLabels[r] })),
}

export { RoleService }
