import Preschool, { Area } from 'data-models/preschool'
import React, { useState, useContext, createContext, useEffect } from 'react'
import { AssignmentType, Option } from 'data-models/misc'
import { range } from 'lodash-es'
import { Followup, Year } from 'data-models/followup'
import { cluster } from 'data-models/cluster'
import { BackgroundVariable } from 'data-models/backgroundVariable'
import { useTranslation } from 'react-i18next'
type FilterValues = {
  preschools: Preschool['id'][]
  areas: Area['id'][]
  clusters: cluster['name'][]
  followups: Followup['name'][]
  assignmentTypes: AssignmentType['id'][]
  ages: Option['value'][]
  years: Option['value'][]
  backgroundVariabels: BackgroundVariable['name'][]
}

const FilterContext = createContext<{
  preschools: Partial<Preschool>[]
  setPreschools: (preschools: Partial<Preschool>[]) => void
  areas: Area[]
  setAreas: (areas: Area[]) => void
  clusters: cluster[]
  setClusters: (followups: cluster[]) => void
  followups: Followup[]
  setFollowups: (followups: Followup[]) => void
  backgroundVariabels: BackgroundVariable[]
  setBackgroundVariables: (backgroundVariabels: BackgroundVariable[]) => void
  assignmentTypes: AssignmentType[]
  setAssignmentTypes: (assignmentTypes: AssignmentType[]) => void
  ages: Option[]
  years: Option[]
  setAges: (ages: Option[]) => void
  filterValues: FilterValues
} | null>(null)

function FilterProvider(props: { children: React.ReactNode }) {
  const [preschools, setPreschools] = useState([] as Partial<Preschool>[])
  const [areas, setAreas] = useState([] as Area[])
  const [clusters, setClusters] = useState([] as cluster[])
  const [followups, setFollowups] = useState([] as Followup[])
  const { t } = useTranslation()
  const [backgroundVariabels, setBackgroundVariables] = useState(
    [] as BackgroundVariable[]
  )
  const [ages, setAges] = useState(
    range(2, 7).map(x => ({ value: x.toString(), label: `${x} ${t('år')}` }))
  )

  const [years, setYears] = useState([] as Year[])
  const [assignmentTypes, setAssignmentTypes] = useState([] as AssignmentType[])
  const [filterValues, setFilterValues] = useState({} as FilterValues)

  useEffect(() => {
    if (followups) {
      const transformedData = followups.map(item => ({
        value: item.year,
        label: `${item.year.slice(-2)}/${(parseInt(item.year.slice(-2)) + 1)
          .toString()
          .slice(-2)}`,
      }))

      const uniqueData = [
        ...new Set(transformedData.map(item => item.value)),
      ].map(value => {
        return transformedData.find(item => item.value === value)
      }) as Year[]

      setYears(uniqueData)
    }
  }, [followups])

  useEffect(() => {
    setFilterValues({
      preschools: preschools.map(x => x.id!),
      areas: areas.map(x => x.id),
      clusters: clusters.map(x => x.name),
      followups: followups.map(x => x.name),
      backgroundVariabels: backgroundVariabels.map(x => x.name),
      ages: ages.map(x => x.value),
      assignmentTypes: assignmentTypes.map(x => x.id),
      years: years.map(x => x.value),
    })
  }, [
    preschools,
    areas,
    clusters,
    followups,
    backgroundVariabels,
    ages,
    assignmentTypes,
  ])
  const value = {
    preschools,
    setPreschools,
    areas,
    setAreas,
    clusters,
    setClusters,
    followups,
    setFollowups,
    backgroundVariabels,
    setBackgroundVariables,
    assignmentTypes,
    setAssignmentTypes,
    ages,
    setAges,
    filterValues,
    years,
    setYears,
  }

  return (
    <FilterContext.Provider value={value}>
      {props.children}
    </FilterContext.Provider>
  )
}

function useFilters() {
  const ctx = useContext(FilterContext)
  if (ctx === null) throw new Error('hook must be used inside its provider')
  return ctx
}

export { FilterProvider, useFilters }
