import axios from 'axios'
import { axiosAPIInstance } from './api'
import { client } from 'axios-oauth-client'
import { cookies } from './cookies'
import jwtDecode from 'jwt-decode'
import { env, isLocalhostEnv, populateEnv } from './environment'
import { skolanalysModules } from 'common/utils'
import User from 'data-models/user'

interface AccessToken  {
  name: string;
  exp: number;
}

const AuthService = {
  getUser: () => {
    return new Promise<User | null>((resolve) => {
      const token = AuthService.getToken();
      if (token) {
        try {
          const decodedToken = jwtDecode<AccessToken>(token);
          const currentTime = Math.floor(Date.now() / 1000);
          if (decodedToken.exp < currentTime) {
            AuthService.logout();
          } else {
            axiosAPIInstance.get(`/profile`).then((u) => {
              const apiUser = u.data;
              const forskolaRole = apiUser.roles.find((x) =>
                x.includes(skolanalysModules.forskola)
              );
              const user = {
                ...apiUser,
                role: forskolaRole,
              } as User;
  
              resolve(user);
            })
            .catch((error) => {
              console.log('An error occurred:', error);
              AuthService.logout();
            });
          }
        } catch (error) {
          console.log('Error decoding token:', error);
          AuthService.logout();
        }
      } else {
        AuthService.logout();
      }
    });
  },

  login: () =>
    isLocalhostEnv() ? AuthService.manualLogin() : Promise.resolve(),

  logout: () => {
    cookies.remove('token');
    cookies.remove('refresh_token');
    localStorage.removeItem('token');
    localStorage.clear();
    window.location.assign(populateEnv().baseUrl+`login?logout=true`);
  },

  storeTokens: ({
    access_token,
    refresh_token,
  }: {
    access_token: string
    refresh_token: string
  }) => {
    cookies.set('token', access_token)
    cookies.set('refresh_token', refresh_token)
  },

  getToken: (): string => cookies.get('token'),

  getRefreshToken: (): string => cookies.get('refresh_token'),

  refreshToken: () => {
    return client(axios.create(), {
      url: env().tokenUrl,
      grant_type: 'refresh_token',
      client_id: 'skolanalys-ui',
      refresh_token: AuthService.getRefreshToken(),
    })()
      .then((data: { access_token: any; refresh_token: any }) => {
        AuthService.storeTokens(data);
        return data;
      })
      .catch((error) => {
        // Handle the error here
        console.log('Error refreshing token:', error);
        AuthService.logout();
      });
  },
    

  manualLogin: () => {
    return client(axios.create(), {
      url: env().tokenUrl,
      grant_type: 'password',
      client_id: 'skolanalys-ui',
      scope: 'skolanalys-api profile offline_access',
      username: env().username,
      password: env().password,
    })().then(AuthService.storeTokens)
  },
}

export { AuthService }
