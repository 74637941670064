import React, { Suspense, useEffect, useState } from 'react'
import { AuthService } from 'services/authService'
import { MunicipalityService } from 'services/municipalityService'
import AuthError from 'common/components/AuthError'
import AuthLoading from 'common/components/AuthLoading'
import { useUser } from 'providers/UserProvider'
import { useMunicipality } from 'providers/MunicipalityProvider'
import { PreschoolService } from 'services/preschoolService'
import { RoleService } from 'services/roleService'
import { useFilters } from 'providers/FilterProvider'
import { useHistory } from 'react-router-dom'
import User from 'data-models/user'

const AuthenticatedApp = React.lazy(() => import('./AuthenticatedApp'))

export default function App() {
  const { user, setUser } = useUser()
  const [isLoggingIn, setIsLoggingIn] = useState(true)
  const [loginError, setLoginError] = useState<string | undefined>(undefined)
  const { municipality, setMunicipality, setMunicipalities } = useMunicipality()
  const {
    setPreschools,
    setAreas,
    setAssignmentTypes,
    setFollowups,
    setClusters,
    setBackgroundVariables,
  } = useFilters()
  const history = useHistory()
  const [showAuthError, setShowAuthError] = useState(false)

  useEffect(() => {
    async function initialLogin() {
      try {
        await AuthService.login()
        const user = (await AuthService.getUser()) as User | null

        if (!user?.role)
          throw new Error('You are not authorized to use this module!')
        else setUser(user)

        const municipality = await MunicipalityService.getMunicipality(
          user.organizationId
        )
        setMunicipality(municipality)

        if (RoleService.isSuperAdmin(user)) {
          const municipalities = await MunicipalityService.getMunicipalities()
          setMunicipalities(municipalities)
        }
      } catch (error: any | Error) {
        const errMsg =
          error instanceof Error ? error.message : error.response.statusText
        setLoginError(errMsg)
      } 
    }
    initialLogin()
  }, [])

  useEffect(() => {
    if (municipality.code) {
      Promise.all([
        PreschoolService.getPreschoolsForCurrentUser(user, municipality),
        PreschoolService.getAreasForCurrentUser(municipality),
        PreschoolService.getAssignmentTypes(),
        PreschoolService.getFollowup(),
        PreschoolService.getCluster(),
        PreschoolService.getBackgroundVariables(),
      ])
        .then(
          ([
            preschools,
            areas,
            assignmentTypes,
            followup,
            cluster,
            backgroundVariabels,
          ]) => {
            setPreschools(preschools)
            setAreas(areas)
            setAssignmentTypes(assignmentTypes)
            setFollowups(followup)
            setClusters(cluster)
            setBackgroundVariables(backgroundVariabels)
          }
        )
        .finally(() => {
          setIsLoggingIn(false)
        })
    }
  }, [user, municipality])

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (!AuthService.getToken()) {
        setShowAuthError(true)
      } else {
        setShowAuthError(false)
      }
    })

    // Clean up the listener when the component unmounts
    return () => {
      unlisten()
    }
  }, [history])

  if (isLoggingIn) return <AuthLoading />

  if (loginError || showAuthError) return <AuthError />

  return (
    <Suspense fallback="Loading...">
      <AuthenticatedApp />
    </Suspense>
  )
}
