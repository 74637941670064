import { gql } from '@apollo/client'
import { BackgroundVariable } from 'data-models/backgroundVariable'
import { cluster } from 'data-models/cluster'
import { Followup } from 'data-models/followup'
import { AssignmentType } from 'data-models/misc'
import { orderBy } from 'lodash-es'
import Municipality from '../data-models/municipality'
import Preschool, { Area } from '../data-models/preschool'
import { BaseUser } from '../data-models/user'
import { graphQlClient } from './graphql'
import { RoleService } from './roleService'

export const PreschoolService = {
  GET_PRESCHOOL: gql`
    query Preschool($preschoolId: Int!) {
      preschool(id: $preschoolId) {
        name
        municipality {
          name
          county
        }
        contact {
          name
          email
          phone
          address
          zip
          city
        }
      }
    }
  `,

  UPDATE_PRESCHOOL: gql`
    mutation Preschool(
      $preschoolId: Int!
      $name: String!
      $email: String!
      $phone: String!
      $address: String!
      $zip: String!
      $city: String!
    ) {
      updatePreschoolContact(
        preschoolId: $preschoolId
        contact: {
          name: $name
          email: $email
          phone: $phone
          address: $address
          zip: $zip
          city: $city
        }
      )
    }
  `,

  GET_MUNICIPALITY_PRESCHOOLS_BASIC: gql`
    query Preschools($municipalityId: Int!) {
      preschools(municipalityId: $municipalityId) {
        id
        name
        areaId
        contact {
          email
          phone
        }
      }
    }
  `,

  GET_AVAILABLE_PRESCHOOLS_BASIC: gql`
    query PreschoolsByIds($preschoolIds: [Int!]) {
      preschoolsByIds(ids: $preschoolIds) {
        id
        name
        areaId
        contact {
          email
          phone
        }
      }
    }
  `,

  GET_MUNICIPALITY_AREAS_BASIC: gql`
    query Areas($municipalityId: Int!) {
      areas(municipalityId: $municipalityId) {
        id
        name
      }
    }
  `,

  GET_ASSIGNMENT_TYPES: gql`
    query Assignments {
      assignments {
        id
        name
        code
        year
        category
      }
    }
  `,

  GET_FOLLOWUPS: gql`
  query Followups {
    followups {
      id
      name
      year
    }
  }
`,

  GET_CLUSTERS: gql`
query Clusters {
  clusters {
    id
    name
    followupId
  }  
}
`,

  GET_BACKGROUND_VARIABLES: gql`
query BackgroundVariables {
  backgroundVariables {
    id
    name
    localName
  }
}
`,


  getPreschoolsForCurrentUser: (user: BaseUser, municipality: Municipality) => {
    let query
    let variables: any
    let resultKey: string

    if (RoleService.hasMunicipalitySchoolsSubset(user)) {
      query = PreschoolService.GET_AVAILABLE_PRESCHOOLS_BASIC
      variables = { preschoolIds: user.schoolIds }
      resultKey = 'preschoolsByIds'
    } else {
      query = PreschoolService.GET_MUNICIPALITY_PRESCHOOLS_BASIC
      variables = {
        municipalityId: municipality.code,
      }
      resultKey = 'preschools'
    }

    return graphQlClient
      .query({
        query,
        variables,
      })
      .then(({ data }) => {
        const arrayForSort = [...data[resultKey]]
        return (orderBy(arrayForSort, x => x.name) ||
          []) as Partial<Preschool>[]
      })
  },

  getAreasForCurrentUser: (municipality: Municipality) =>
    graphQlClient
      .query({
        query: PreschoolService.GET_MUNICIPALITY_AREAS_BASIC,
        variables: {
          municipalityId: municipality.code,
        },
      })
      .then(({ data }) => data.areas as Area[]),

  getAssignmentTypes: () =>
    graphQlClient
      .query({
        query: PreschoolService.GET_ASSIGNMENT_TYPES,
      })
      .then(({ data }) => data.assignments as AssignmentType[]),

  getFollowup: () =>
    graphQlClient
      .query({
        query: PreschoolService.GET_FOLLOWUPS,
      })
      .then(({ data }) => {
        const followups = data.followups as Followup[];
        return [...followups].reverse();
      }),

  getCluster: () =>
    graphQlClient
      .query({
        query: PreschoolService.GET_CLUSTERS,
      })
      .then(({ data }) => data.clusters as cluster[]),

  getBackgroundVariables: () =>
    graphQlClient
      .query({
        query: PreschoolService.GET_BACKGROUND_VARIABLES,
      })
      .then(({ data }) => data.backgroundVariables as BackgroundVariable[]),
}
