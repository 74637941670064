import axios, { AxiosInstance } from 'axios'
import { AuthService } from './authService'
import { env } from './environment'

// https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token
export let axiosAPIInstance: AxiosInstance

export function createAxiosInstance() {
  axiosAPIInstance = axios.create({
    baseURL: env().apiBase,
  })

  // Request interceptor for API calls
  axiosAPIInstance.interceptors.request.use(
    config => {
      config.headers.Authorization = `Bearer ${AuthService.getToken()}`
      return config
    },

    err => Promise.reject(err)
  )

  // Response interceptor for API calls
  axiosAPIInstance.interceptors.response.use(
    response => response,

    async error => {
      const originalRequest = error.config

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true

        await AuthService.refreshToken()

        return axiosAPIInstance({
          ...originalRequest,
          headers: {
            ...originalRequest.headers,
            Authorization: `Bearer ${AuthService.getToken()}`,
          },
        })
      }

      return Promise.reject(error)
    }
  )
}
