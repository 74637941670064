import {
  ApolloClient,
  ApolloLink,
  concat,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { AuthService } from './authService'
import { env } from './environment'

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer  ${AuthService.getToken()}`,
    },
  }))

  return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )

  if (networkError) console.error(`[Network error]: ${networkError}`)
})

export let graphQlClient: ApolloClient<NormalizedCacheObject>

export function createGraphQlClient() {
  const httpLink = new HttpLink({
    uri: env().graphQlBase,
  })

  graphQlClient = new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false, // remove __typename from response
    }),
    link: concat(errorLink, concat(authMiddleware, httpLink)),
  })
}
