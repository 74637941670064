import React, { useEffect } from 'react'
import { Text } from '@chakra-ui/react'
import { populateEnv } from 'services/environment'
import FullPageSpinner from './FullPageSpinner'
import { useTranslation } from 'react-i18next'

export default function AuthError() {

  const { t, i18n } = useTranslation()
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.assign(populateEnv().baseUrl + `login?logout=true`)
    }, 300)

    return () => clearTimeout(timeout)
  })

  return (
    <FullPageSpinner>
      <Text color="white" fontSize="2xl">
        {t('Authenticating_Spinner')}
      </Text>
    </FullPageSpinner>
  )
}
